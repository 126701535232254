import React from "react";
import Layout from 'layouts/layout'
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// core components
import styles from "assets/jss/nextjs-material-kit/pages/landingPage.js";
import { Link } from 'gatsby';

const UseStyles = makeStyles(styles);

export default function dzienJednosci2019(props) {
    const classes = UseStyles();
    return (
        <Layout>
            <div className={classNames(classes.main, classes.mainRaised)}>
                <div className={classes.container} style={{ display: 'flex', flexFlow: 'row wrap' }}>
                    <img src={'/news/sod-opole-2019-plakat.jpg'} alt={''} style={{ padding: '5px 5px 30px 5px', margin: '7vw auto 0', width: '100%' }} />
                    <p style={{color: 'black', padding: '10px 20px', margin: '0 auto', fontSize: '1em', width: '100%'}}>Chcesz odnaleźć radość i nadzieję? </p>
                    <p style={{color: 'black', padding: '10px 20px', margin: '0 auto', fontSize: '1em', width: '100%'}}>Chcesz pogłębić swoją wiarę?</p>
                    <p style={{color: 'black', padding: '10px 20px', margin: '0 auto', fontSize: '1em', width: '100%'}}>Chcesz doświadczyć miłości?</p>
                    <p style={{color: 'black', padding: '10px 20px', margin: '0 auto', fontSize: '1em', width: '100%'}}>Potrzebujesz uzdrowienia? </p>
                    <p style={{color: 'black', padding: '10px 20px', margin: '0 auto', fontSize: '1em', width: '100%'}}>Przyjdź! Zobacz! Doświadcz!</p>
                    <p style={{color: 'black', padding: '10px 20px', margin: '0 auto', fontSize: '1em', width: '100%'}}>Rekolekcje to cykl dziewięciu spotkań, które pomogą Ci zwrócić się ku Jezusowi Chrystusowi, oraz w przyjęciu  osoby Ducha Świętego, Jego obecności i mocy.</p>
                    <p style={{color: 'black', padding: '10px 20px', margin: '0 auto', fontSize: '1em', width: '100%'}}>Jezus zna dobrze twoją sytuację życiową, twoje pragnienia, plany, radości, a także twoją słabość,  grzech,  zniewolenie. To on wie jak zaspokoić najgłębsze pragnienia twojego serca. chce przyjść z łaską przebaczenia, uzdrowienia, uwolnienia, żebyś żył pełnią życia, w radości dziecka bożego . Potrzebuje twojej zgody, twojej obecności, żeby mógł uczynić twoje życie pięknym.</p>
                    <p style={{color: 'black', padding: '10px 20px', margin: '0 auto', fontSize: '1em', width: '100%'}}>Przyjdź! Zobacz! Doświadcz !</p>
                    
                    <hr style={{width: '100%'}}/>

                    <a href="http://www.sne.opole.pl/pl/1699/821/kurs-kamch.html" style={{ padding: '5px', margin: '0 auto', width: '100%' }}> 
                        <img src={'/communitiesSlides/kurs-animacji-modlitwy-charyzmatycznej.jpg'} alt={''} style={{ padding: '5px 5px 30px 5px', margin: '7vw auto 0', width: '100%' }} />                    
                    </a>
                    <p style={{ color: 'black', padding: '10px 20px', margin: '0 auto', fontSize: '1em', width: '100%' }} >
                        W dnaich 15-16 lsitopada 2019 odbędzie się kurs animacji modlitwy charyzmatycznej. Koszt 50zł, więcej szczegółów na kliknięciu w plakat… 
                    </p>
                    <hr style={{width: '100%'}}/>

                    <p style={{ color: 'black', padding: '30px 10px 10px 10px', margin: '10vw auto 20px ', fontSize: '1em' }} >
                        Ruszyła nowa strona internetowa Katolickiej Odnowy w Duchu Świętym diecezji opolskiej. Strona jest jeszcze w fazie budowy, a więc prosimy o wyrozumiałość. Jednocześnie zachęcamy do współtworzenia tej przestrzeni, która ma służyć nam wszystkim. Wszelkie uwagi i podpowiedzi dotyczące jej wyglądu oraz funkcjonowania prosimy wysyłać pod adres mailowy 
                        <a href="mailto: sekretariat@opole.odnowa.org"> sekretariat@opole.odnowa.org</a>.
                    </p>
                    <hr style={{width: '100%'}}/>

                    <Link to="/jereo-2019" style={{ padding: '5px', margin: '0 auto', width: '100%' }}>
                        <img src={'/generalSlides/jereo-2019.jpg'} alt={'plakat jereo 2019'} style={{ padding: '5px 5px 30px 5px', margin: '7vw auto 0', width: '100%' }} />
                    </Link>
                    <p style={{ color: 'black', padding: '10px', margin: '20px auto', fontSize: '1em' }} >
                        Dnia 1 października br. ruszyły zapisy na coroczne Jesienne Rekolekcje Odnowy w Duchy Świętym naszej diecezji. Rekolekcje te odbędą się w dniach 8-10 listopada w Domu Pielgrzyma na Górze św. Anny. Zapisy trwają do 31 października. Liczba miejsc ograniczona. Więcej szczegółów na kliknięciu w plakat… 
                    </p>
                    <hr style={{width: '100%'}}/>

                    <Link to="/dzien-jednosci-2019" style={{ padding: '5px', margin: '0 auto', width: '100%' }}>
                        <img src={'/generalSlides/dzien-jednosci-2019.png'} alt={'plakat jereo 2019'} style={{ padding: '5px 5px 30px 5px', margin: '7vw auto 0', width: '100%' }} />
                    </Link>
                    <p style={{ color: 'black', padding: '10px', margin: '20px auto 50px', fontSize: '1em' }} >
                        Dnia 21 września br. przy Wyższym Miedzydiecezjalnym Seminarium Duchownym w Opolu obył się Dzień Jedności Katolickiej Odnowy w Duchu Świętym diecezji opolskiej. Na spotkanie przybyło ok. 200 osób z 15 wspólnot i grup modlitewnych. Był to czas wspólnej modlitwy, uwielbienie Boga, integracji oraz wymiany spostrzeżeń na temat obecnego oraz planowanego działania Odnowy w naszej diecezji. Uroczystej Eucharystii przewodniczył bp Andrzej Czaja, który w ramach homilii wygłosił zachętę do jeszcze gorliwszego przyzywania Ducha Świętego oraz trwania w jedności doktrynalnej i braterskiej. Patronem medialnym wydarzenia był tygodnik „Gość Niedzielny” oraz radio „Doxa”.  Zapraszamy do galerii zdjęć… 
                    </p>
                </div>
            </div>
        </Layout>
    );
}
